import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { gsap, TimelineLite } from "gsap";
import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";
import { usePathname } from "next/navigation";
import { motion } from "framer-motion";

const PageTransition = () => {
  const tl = new TimelineLite();
  const pathName = usePathname();
  gsap.registerPlugin(DrawSVGPlugin);

  useEffect(() => {
    tl.fromTo(
      ".page-change",
      { display: "flex", opacity: 1 },
      { duration: 1, delay: 1, opacity: 0, display: "none" }
    );
  }, [pathName]);

  return (
    <StyledComponent className={"page-change"}>
      <div className="wrapper-text-motion">
        {/*<img src="/images/static/logo.svg" alt="logo"/>*/}
        <h1>Your partner for growth</h1>
      </div>
    </StyledComponent>
  );
};
const leftToRight = keyframes`
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;
const StyledComponent = styled.section`
  height: 100vh;
  position: fixed;
  background: #191919;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper-text-motion {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      animation: ${leftToRight} 1.3s ease forwards;
    }
  }

  h1 {
    color: white;
    font-size: 2rem;
    text-align: center;
    animation: ${leftToRight} 0.8s ease forwards;
  }
`;

const MotionText = styled(motion.h1)`
  color: white;
  font-size: 2rem;
  text-align: center;
`;

export default PageTransition;
