'use client'

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Preloader = () => {
    return (
        <PreloaderWrapper>
            <div className="wrapper-text-motion">
                <MotionText
                    initial={{ x: '-100%', opacity: 0 }}
                    animate={{ x: '0%', opacity: 1 }}
                    exit={{ x: '100%', opacity: 0  }}
                    transition={{ ease: 'easeInOut', duration: 2 }}
                >
                    Your partner for growth
                </MotionText>
            </div>
        </PreloaderWrapper>
    );
};

const PreloaderWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #191919;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999999999999999;
    .wrapper-text-motion{
        overflow: hidden;
        position: relative;
    }
`;

const MotionText = styled(motion.h1)`
    color: white;
    font-size: 2rem;
    text-align: center;
`;

export default Preloader;
