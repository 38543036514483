'use client';
import {useEffect, useLayoutEffect, useRef} from 'react';
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {ScrollSmoother} from "gsap/dist/ScrollSmoother";
import {gsap} from "gsap";
import {usePathname} from "next/navigation";
import {SplitText} from "gsap/SplitText";
import {Parallax} from "@/components/animation/Parallax";
import {SmootherContext} from '@/components/SmootherContext';
import { CustomScroll } from "react-custom-scroll";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);

export default function SmoothScroll({ children }) {
    const wrapper = useRef(null);
    const content = useRef(null);
    const location = usePathname();
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 992;
    const smoother = useRef(null);
    const scrollbar = useRef(null);
    Parallax();
    const useIsomorphicLayoutEffect = typeof window !== 'undefined'
        ? useLayoutEffect
        : useEffect;

    useIsomorphicLayoutEffect(() => {



        const ctx = gsap.context(() => {

            if (!ScrollTrigger.isTouch) {
                smoother.current = ScrollSmoother.create({
                    wrapper: wrapper.current,
                    content: content.current,
                    smooth: isMobile ? 0 : 2,
                    effects: !isMobile,
                    smoothTouch: 0.1,
                    // normalizeScroll: {
                    //     allowNestedScroll: !isMobile,
                    // },
                    // ignoreMobileResize: !isMobile,
                    // preventDefault: true,
                }, wrapper);

                ScrollTrigger.refresh();



            }
        }, wrapper);
        return () => {
            ctx.revert();
        };
    }, [location]);

    return (
        <SmootherContext.Provider value={smoother.current}>
            <div id="smooth-wrapper" style={{overflow: 'hidden'}} ref={wrapper}>
                <div ref={content}
                     id="smooth-content"
                >

                    {children}
                </div>
            </div>
        </SmootherContext.Provider>
    );
}

