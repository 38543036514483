"use client";

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { usePathname } from "next/navigation";
import { gsap } from "gsap";

const AnimatedIconMoon = () => {
  const location = usePathname();
  const isDesktop = typeof window !== "undefined" && window.innerWidth > 991;
  useEffect(() => {
    // Set initial properties
    if (isDesktop) {
      gsap.set(".reveal-up-inner-sticky", { y: "50%" });

      // Animation
      gsap.fromTo(
        ".reveal-up-inner-sticky",
        { y: "50%" },
        {
          delay: 1.5,
          y: "0",
          duration: 1.5, // Adjust the duration as needed
          ease: "power3.out", // Experiment with different easing functions
        }
      );
    }
  }, [location]);

  useEffect(() => {
    // Set initial state
    gsap.set(".sticky-menu-middle", { opacity: 0 });

    gsap.to(".sticky-menu-middle", {
      opacity: 1,
      ease: "easeInOut",
      duration: 1.5,
      delay: 2,
    });
  }, [location]);

  return (
    <StyledMessage className="sticky-menu-middle reveal-up-inner-sticky">
      <div className={"icons-wrapper"}>
        <div className={"sticky-icons"}>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.usl.ucb_stock&hl=en&pli=1"
          >
            <div className={"sticky-icons__left"}>
              <img src={"/images/dynamic/call-icon.png"} alt={"Call Icon"} />
              <p>UInvest</p>
            </div>
          </a>
        </div>
      </div>
    </StyledMessage>
  );
};

const StyledMessage = styled.div`
  position: fixed;
  right: 25px;
  bottom: 100px;
  z-index: 9999;

  .sticky-icons {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      overflow: hidden;
      position: relative;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 16%);
      background: white;
      transition: 0.7s all ease;
      //@media (min-width: 1600px) {
      //  height: 60px;
      //  width: 60px;
      //  border-radius: 30px;
      //}
      .sticky-icons__left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 50px;
        padding-left: 37px;
        padding-top: 5px;
        transition: 0.7s all ease;

        img {
          margin-right: 10px;
          height: 30px;
          width: 26px;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: #191919;
          opacity: 0;
          visibility: hidden;
          transition: 0.7s all ease;
        }
      }

      &:hover {
        width: 130px;
        .sticky-icons__left {
          padding-left: 0;

          p {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    right: 15px;
  }
`;

export default AnimatedIconMoon;
