'use client'

import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { usePathname } from "next/navigation";
import Preloader from './Preloader'; // Adjust the path if necessary

const PageWrapper = ({ children }) => {
    const pathName = usePathname();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2500); // Adjust the delay as needed
        return () => clearTimeout(timer);
    }, [pathName]);

    return (
        <StyledComponent>
            <AnimatePresence mode="wait">
                {loading ? (
                    <motion.div
                        key="preloader"
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 1 }}
                    >
                        <Preloader />
                    </motion.div>
                ) : (
                    <motion.div
                        key={pathName}
                        initial={{ opacity: 1, zIndex: 99999 }}
                        animate={{ opacity: 1, zIndex: -1 }}
                        exit={{ opacity: 0, zIndex: 99999 }}
                        transition={{ duration: 1, ease: 'easeInOut' }}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background: #191919;
    z-index: 9999999999999999999;
`;

export default PageWrapper;
