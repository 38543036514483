"use client";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Img } from "@/components/Img";
import Link from "next/link";
import Image from "next/image";
import Logo from "@/public/images/static/logo.png";
import { hover, text, Transition, White } from "@/styles/globalStyleVars";
import { useEffect, useState } from "react";
import { CSSPlugin, gsap, TimelineLite } from "gsap";
import { usePathname, useRouter } from "next/navigation";
import Cookies from "js-cookie";
import { useGSAP } from "@gsap/react";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

const Component = ({ getMenuClass }) => {
  gsap.registerPlugin(CSSPlugin);
  const pathName = usePathname();
  const [index, setIndex] = useState(0);
  const [data, setData] = useState(null);

  useEffect(() => {
    const tl = new TimelineLite();
    const tls = new TimelineLite();
    let isAnimating = false;

    const isDesktop = typeof window !== "undefined" && window.innerWidth > 768;

    const disableScroll = (e) => {
      document.body.classList.add("pointer-events");
      setTimeout(() => disableBodyScroll(e), 200);
      setTimeout(() => document.body.classList.remove("pointer-events"), 300);
    };

    const enableScroll = (e) => {
      setTimeout(() => enableBodyScroll(e), 200);
    };

    // Open menu for desktop
    const openMenuDesktop = () => {
      if (isAnimating) return;
      isAnimating = true;

      document.querySelector(".Main-menu").classList.add("menu-opened");
      document.body.classList.add("menu-opened");

      tl.to(".Main-menu__open", 0, { display: "block" })
          .to(".Main-menu__open", { y: 0, duration: 0.3 })
          .to(".Main-menu__open__items__single", { y: 0, stagger: 0.03 })
          .to(
              [".Main-menu__open__items__single h4,.Main-menu__open__items__single li"],
              { opacity: 1, duration: 0.05 },
              "-=0.4"
          )
          .to(".Main-menu__open__bottom__single", { opacity: 1, y: 0 }, "-=0.3")
          .eventCallback("onComplete", () => {
            isAnimating = false;
          });
    };

    // Close menu for desktop with optimized timing
    const closeMenuDesktop = () => {
      if (isAnimating) return;
      isAnimating = true;

      tls.to(".Main-menu__open__items__single", { y: "20px", stagger: 0.02 })
          .to(
              [".Main-menu__open__items__single h4,.Main-menu__open__items__single li"],
              { opacity: 0, duration: 0.05 },
              "-=0.2"
          )
          .to(".Main-menu__open__bottom__single", { opacity: 0, y: "20px" }, "-=0.2")
          .to(".Main-menu__open", { y: "-100%", duration: 0.4 }, "-=0.3")
          .to(".Main-menu__open", { display: "none" })
          .eventCallback("onComplete", () => {
            document.querySelector(".Main-menu")?.classList?.remove("menu-opened");
            document.querySelector("body")?.classList?.remove("menu-opened");
            isAnimating = false;
          });

      // Shorten the timeout for removing the class
      setTimeout(() => {
        document.querySelector(".Main-menu")?.classList?.remove("menu-opened");
        document.querySelector("body")?.classList?.remove("menu-opened");
      }, 200);
    };

    const toggleMenuDesktop = (e) => {
      const menu = document.querySelector(".Main-menu");
      if (menu?.classList.contains("menu-opened")) {
        enableScroll(e);
        closeMenuDesktop();
      } else {
        disableScroll(e);
        openMenuDesktop();
      }
    };

    const setupDesktopListeners = () => {
      const hamburgerClick = document.querySelector(
          ".Main-menu__wrap__menu__hamburger .click"
      );
      if (hamburgerClick) {
        hamburgerClick.addEventListener("click", toggleMenuDesktop);

        window.addEventListener("click", (e) => {
          if (
              !document.body.classList.contains("pointer-events") &&
              !e.target.matches(
                  ".Main-menu__wrap__menu__hamburger .click,.Main-menu__wrap__menu__hamburger .click div,.click .Main-menu__wrap__menu__hamburger__line .init,.click .Main-menu__wrap__menu__hamburger__text,.Main-menu__wrap__menu__hamburger .click span,.Main-menu__wrap__menu__hamburger .click p,.Main-menu__open__top__logo,.Main-menu__open__top__logo img, .Main-menu__open__items, .Main-menu__open__items__single, .Main-menu__open__items__single h4, .Main-menu__open__items__single ul,.Main-menu__open__items__single li,.Main-menu__open__bottom, .Main-menu__open__bottom__single p, .Main-menu__open__bottom__single, .Main-menu__open__bottom__single a, .Main-menu__open__bottom__single ul, .Main-menu__open__bottom__single li,.Main-menu__open__bottom__single a"
              )
          ) {
            enableScroll(e);
            closeMenuDesktop();
          }
        });
      }
    };

    if (isDesktop) {
      setupDesktopListeners();
    }

    return () => {
      const hamburgerClick = document.querySelector(
          ".Main-menu__wrap__menu__hamburger .click"
      );
      if (hamburgerClick) {
        hamburgerClick.removeEventListener("click", toggleMenuDesktop);
      }
      window.removeEventListener("click", closeMenuDesktop);
    };
  }, []);




  useEffect(() => {
    const getParentUl = document.querySelectorAll(
      ".Main-menu__open__items__single"
    );
  }, []);

  // menu fixed on scroll
  useEffect(() => {
    if (document.body.classList.contains("scroll-down")) {
      document.body.classList.remove("scroll-down");
    }
  });
  useEffect(() => {
    const body = document.body;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;
    let howMuchScroll = 50;


    window.addEventListener("scroll", () => {
      let currentScroll = window.pageYOffset;

      if (currentScroll <= howMuchScroll) {
        body.classList.remove(scrollUp);
        return;
      }
      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (
        currentScroll < lastScroll &&
        body.classList.contains(scrollDown)
      ) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
      lastScroll = currentScroll;
    });
  }, [pathName]);

  // on click sub menu hide
  useEffect(() => {
    const getAllSub = document.querySelectorAll(".Main-menu__wrap__menu a");
    getAllSub.forEach((e) => {
      e.addEventListener("click", () => {
        gsap.to(".Main-menu__wrap__menu ul li ul", 0, {
          display: "none",
        });
        setTimeout(() => {
          gsap.to(".Main-menu__wrap__menu ul li ul", {
            display: "block",
          });
        }, 500);
      });
    });
  }, []);

  // menu offset
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const offsetFunk = () => {
      setOffset(document.querySelector(".footer .container").offsetLeft);
    };
    window.addEventListener("resize", () => {
      offsetFunk();
    });
    offsetFunk();
  }, []);

  useEffect(() => {
    // Set initial state
    gsap.set(".Main-menu", { opacity: 0 });

    if (offset > 0) {
      gsap.to(".Main-menu", {
        opacity: 1,
        ease: "easeInOut",
        duration: 1,
        delay: 1,
      });
    }
  }, [offset, pathName]);

  const router = useRouter();
  const token = Cookies.get("user-token");
  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (token) {
      setLogin(true);
    }
  }, [token]);

  const handleLogout = (selected) => {
    Cookies.remove("user-token", { path: "/" }); // removed!
    Cookies.remove("user-token-auth", { path: "/" }); // removed!
    window.location.href = "/";
    // window.location.href = 'https://ucb-dcastaliawebs-projects.vercel.app';
  };

  // api call
  useEffect(() => {
    fetch("https://cms.ucbstock.com.bd/wp-json/get-settings/all")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  useEffect(() => {
    const getParentUl = document.querySelectorAll(
      ".Main-menu__open__items__single"
    );
    const getImageList = document.querySelectorAll(".Main-menu__open__bg li");
    getParentUl.forEach((e, i) => {
      e.addEventListener("mouseover", () => {
        document.querySelector(".Main-menu").classList.add("menu-hover");
        setIndex(1);
        gsap.to(getImageList[i], {
          opacity: 1,
          x: 0,
        });
      });
      e.addEventListener("mouseleave", () => {
        document.querySelector(".Main-menu").classList.remove("menu-hover");
        gsap.to(getImageList[i], {
          opacity: 0,
          x: "30px",
        });
      });
    });
  }, [data]);

  return (
    <StyledComponent className={`Main-menu ${getMenuClass(pathName)}`}>
      <Container fluid className={"pr-0 hamburger-container"}>
        <div className="Main-menu__wrap">
          <div
            className="Main-menu__wrap__logo"
            style={{ paddingLeft: offset + "px" }}
          >
            <Link prefetch={true} href={"/"}>
              <Image height={60} src={Logo} alt={"logo"} />
            </Link>
          </div>

          <div className="Main-menu__wrap__menu">
            <ul>
              <li
                className={
                  pathName.startsWith("/open-a-bo-account")
                    ? "active single-menu"
                    : "single-menu"
                }
              >
                <Link prefetch={true} href={"/open-a-bo-account"}>
                  Open an Account
                </Link>
              </li>
              <li className={pathName.startsWith("/services") ? "active" : ""}>
                Services
                <ul>
                  <li>
                    <Link prefetch={true} href={"/services/individual-account"}>
                      Individual Account
                    </Link>
                  </li>
                  <li>
                    <Link prefetch={true} href={"/services/corporate-account"}>
                      Corporate Account
                    </Link>
                  </li>
                  <li>
                    <Link
                      prefetch={true}
                      href={"/services/institutional-account"}
                    >
                      Institutional Account
                    </Link>
                  </li>
                  <li>
                    <Link prefetch={true} href={"/services/foreign-account"}>
                      Foreign Account
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className={`${pathName === "/tax-calculator" ? "active" : ""} ${
                  pathName === "/investment-calculator" ? "active" : ""
                }`}
              >
                Calculators
                {/* <Link href={"/investment-calculator"}>/ Calculator</Link> */}
                <ul>
                  <li>
                    <Link  prefetch={true} href={"/investment-calculator"}>
                      Investment Calculator
                    </Link>
                  </li>
                  <li>
                    <Link  prefetch={true} href={"/tax-calculator"}>Tax Calculator</Link>
                  </li>
                </ul>
              </li>

              <li className={pathName === "/research" ? "active" : ""}>
                Research Portal
                <ul>
                  <li>
                    <Link prefetch={true} href={"/research"}>
                      Research Portal
                    </Link>
                  </li>
                  <li>
                    <Link prefetch={true} href={"/ucb-hub#expert"}>
                      Our Experts
                    </Link>
                  </li>
                </ul>
              </li>

              {login ? <li onClick={handleLogout}>/Logout</li> : ""}
            </ul>
            <div className="Main-menu__wrap__menu__hamburger">
              <div className="cta">
                <a
                  target="_blank"
                  href={
                    "https://uinvest.ucbstock.com.bd:8542/auth/login"
                  }
                />
                <img src="/images/static/menu-cta.svg" alt="Menu Image" />
              </div>

              <div className="click-wrap">
                <div
                  className="click"
                  style={{ paddingRight: offset + 15 + "px" }}
                >
                  <div className="Main-menu__wrap__menu__hamburger__text">
                    <p className={"init"}>Menu</p>
                  </div>
                  <div className="Main-menu__wrap__menu__hamburger__line">
                    <span></span>
                    <span></span>
                    <span></span>
                    <img src="/images/static/close.svg" alt="Menu Close Icon" />
                  </div>
                </div>

                <div
                  className="click-close"
                  style={{ paddingRight: offset + 15 + "px" }}
                >
                  <div className="Main-menu__wrap__menu__hamburger__text">
                    <p className={"close-menu"}>Close</p>
                  </div>
                  <div className="Main-menu__wrap__menu__hamburger__line">
                    <img src="/images/static/close.svg" alt="Hamburger Close Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      {/*desktop large menu */}
      <div className="Main-menu__open">
        <div className="Main-menu__open__bg">
          <Img classList={"open-img"} alt={'Menu Image'} src={data?.menu_bg?.[0]?.["opt-image"]} />
          <ul>
            {data?.menu_bg?.slice(1)?.map((item, index) => (
              <li key={index}>
                <Img src={item?.["opt-image"]} alt={'Menu Image' }/>
              </li>
            ))}
          </ul>
        </div>

        <Container>
          {/*menu items*/}
          <div className="Main-menu__open__items">
            <div className="Main-menu__open__items__single">
              <h4>UCB Stock</h4>
              <ul>
                <li>
                  <Link  prefetch={true} href={"/"}>Home</Link>
                </li>
                <li>
                  <Link  prefetch={true} href={"/ucb-hub"}>News & Events</Link>
                </li>
                <li>
                  <Link  prefetch={true} href={"/open-a-bo-account"}>Open an Account</Link>
                </li>
              </ul>
            </div>
            <div className="Main-menu__open__items__single">
              <h4>About Us</h4>
              <ul>
                <li>
                  <Link prefetch={true} href={"/about-us#our_story"}>
                    Our Story
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/about-us#mission_vision"}>
                    Mission, Vision
                  </Link>
                </li>

                <li>
                  <Link prefetch={true} href={"/about-us#corporate_values"}>
                    Corporate Values
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/about-us#management_team"}>
                    Management Team
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/digital-booth"}>
                    Digital Booth
                  </Link>
                </li>
              </ul>
            </div>
            <div className="Main-menu__open__items__single">
              <h4>Services</h4>
              <ul>
                <li>
                  <Link prefetch={true} href={"/services/individual-account"}>
                    Individual Account
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/services/corporate-account"}>
                    Corporate Account
                  </Link>
                </li>
                <li>
                  <Link
                    prefetch={true}
                    href={"/services/institutional-account"}
                  >
                    Institutional Account
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/services/foreign-account"}>
                    Foreign Account
                  </Link>
                </li>
              </ul>
            </div>
            <div className="Main-menu__open__items__single">
              <h4>UCB Hub</h4>
              <ul>
                <li>
                  <Link prefetch={true} href={"/research"}>
                    Research Portal
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/ucb-hub#expert"}>
                    Our Experts
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/ucb-hub#download"}>
                    Download Hub
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/investment-calculator"}>
                    Investment Calculator
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/tax-calculator"}>
                    Tax Calculator
                  </Link>
                </li>
              </ul>
            </div>
            <div className="Main-menu__open__items__single">
              <h4>Misc</h4>
              <ul>
                {/* <li>
                  <Link href={"/services/individual-account"}>
                    Individual Account
                  </Link>
                </li>
                <li>
                  <Link href={"/services/corporate-account"}>
                    Corporate Account
                  </Link>
                </li>
                <li>
                  <Link href={"/services/institutional-account"}>
                    Institutional Account
                  </Link>
                </li> */}
                {/* <li>
                  <Link href={"/services/imperial-account"}>
                    Imperial Account
                  </Link>
                </li> */}

                <li>
                  <Link prefetch={true} href={"/career"}>
                    Career
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/contact-us"}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/*bottom items*/}
          <div className="Main-menu__open__bottom">
            <div className="Main-menu__open__bottom__single">
              <ul>
                {data?.social?.[0]?.["opt-link"]?.url && (
                  <li>
                    <a
                      target={"_blank"}
                      href={data?.social?.[0]?.["opt-link"]?.url}
                    >
                      <img src="/images/static/fb.svg" alt="UCB Stock Brokerage LTD Facebook" />
                    </a>
                  </li>
                )}
                {data?.social?.[1]?.["opt-link"]?.url && (
                  <li>
                    <a
                      target={"_blank"}
                      href={data?.social?.[1]?.["opt-link"]?.url}
                    >
                      <img src="/images/static/insta.svg" alt="UCB Stock Brokerage LTD Instragram" />
                    </a>
                  </li>
                )}
                {data?.social?.[2]?.["opt-link"]?.url && (
                  <li>
                    <a
                      target={"_blank"}
                      href={data?.social?.[2]?.["opt-link"]?.url}
                    >
                      <img src="/images/static/youtube.svg" alt="UCB Stock Brokerage LTD Youtube" />
                    </a>
                  </li>
                )}
                {data?.social?.[3]?.["opt-link"]?.url && (
                  <li>
                    <a
                      target={"_blank"}
                      href={data?.social?.[3]?.["opt-link"]?.url}
                    >
                      <img src="/images/static/in.svg" alt="UCB Stock Brokerage LTD Linkedin" />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  position: fixed;
  width: 100%;
  height: 100px;
  //background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000000000999;
  //overflow: hidden;
  transition: transform 0.6s ease, opacity 0.6s ease, background 0.3s ease;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  opacity: 0;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 999;
  }

  .Main-menu__wrap {
    display: flex;
    height: 100px;
    //justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 999;

    &__logo {
      display: flex;
      height: 100%;
      align-items: center;
    }

    &__menu {
      display: flex;
      justify-content: space-between;
      //gap: 0 60px;
      flex-grow: 1;
      margin-left: 50px;

      ul {
        display: flex;

        li {
          //&:not(:nth-last-of-type(1)) {
          //
          //}
          margin-right: 40px;
          display: flex;
          height: 100px;
          align-items: center;
          font-weight: 500;
          color: #fff;
          cursor: pointer;
          position: relative;

          &.single-menu {
            a {
              height: 100px;
              display: flex;
              align-items: center;
            }
          }

          a {
            color: #fff;
            font-weight: 500;
          }

          &:after {
            content: "";
            position: absolute;
            width: 0;
            left: 0;
            height: 3px;
            bottom: 0;
            background-color: ${hover};
            //opacity: 0;
            transition: 0.4s ease;
          }

          &.active {
            &:after {
              width: 100%;
            }
          }

          ul {
            position: absolute;
            width: 280px;
            display: block;
            background-color: ${White};
            top: 100px;
            padding: 40px;
            box-shadow: 0 0 20px rgba(221, 221, 221, 0.49);
            visibility: hidden;
            opacity: 0;
            transform: translateY(20px);
            transition: 0.3s ease;
            z-index: 99;

            li {
              display: block;
              margin: 0;
              padding: 0;
              height: auto;
              cursor: auto;

              &:after {
                display: none;
              }

              a {
                font-size: 16px;
                line-height: 24px;
                color: ${text};
                border-bottom: 1px solid rgba(38, 32, 30, 0.1);
                margin: 0 0 12px 0;
                padding: 0 0px 12px 0px;
                display: flex;
                width: 100%;
                font-weight: bold;
              }

              &:nth-last-of-type(1) {
                a {
                  border: none;
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }

          &:hover {
            &:after {
              //opacity: 1;
              width: 100%;
            }

            ul {
              visibility: visible;
              opacity: 1;
              transform: none;
            }
          }

          &:nth-last-of-type(1) {
            ul {
              right: 0;
            }
          }
        }
      }

      &__hamburger {
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: end;
        //padding-left: 30px;
        position: relative;

        .click-wrap {
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: end;
          //padding-left: 30px;
          position: relative;
          height: 100%;
          width: 100%;
          background-color: ${hover};

          &:after {
            transition: 0.5s ${Transition};
            content: "";
            position: absolute;
            height: 100%;
            width: 0%;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: ${text};
          }

          &:hover {
            span {
              &:nth-of-type(1) {
                width: 13px;
              }

              &:nth-of-type(3) {
                width: 25px;
              }
            }

            &:after {
              width: 100%;
            }
          }
        }

        .cta {
          min-height: 100px;
          min-width: 100px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            height: 100%;
            width: 0%;
            background-color: rgba(0, 0, 0, 0.08);
            //background-color: red;
            left: 0;
            bottom: 0;
            transition: 0.4s ease;
          }

          a {
            position: absolute;
            height: 100%;
            width: 100%;
            inset: 0;
            z-index: 6;
          }

          img {
            position: relative;
            z-index: 5;
          }

          &:hover {
            &:after {
              width: 100%;
            }
          }
        }

        .click,
        .click-close {
          display: flex;
          align-items: center;
          justify-content: center;
          // background-color: ${hover};
          height: 100%;
          width: 100%;
          position: relative;
          padding-left: 30px;
          z-index: 9;
          // &:after {
          //     transition: .5s ${Transition};
          //     content: '';
          //     position: absolute;
          //     height: 100%;
          //     width: 0%;
          //     left: 0;
          //     top: 0;
          //     bottom: 0;
          //     background-color: ${text};
          // }
          //
          // &:hover {
          //     span {
          //         &:nth-of-type(1) {
          //             width: 13px;
          //         }
          //
          //         &:nth-of-type(3) {
          //             width: 25px;
          //         }
          //     }
          //
          //     &:after {
          //         width: 100%;
          //     }
          // }
        }

        .click-close {
          display: none;
        }

        &__line {
          margin-right: 10px;
          position: relative;
          z-index: 2;
          width: 25px;
          img {
            display: none;
          }

          span {
            height: 2px;
            width: 25px;
            background-color: ${White};
            display: flex;
            transition: 0.5s ease;

            &:nth-of-type(1) {
              margin-bottom: 5px;
              float: right;
            }
            &:nth-of-type(2) {
              margin: 5px 0;
            }

            &:nth-of-type(3) {
              width: 13px;
            }
          }
        }

        &__text {
          position: relative;
          z-index: 2;

          p {
            color: #fff;
            margin-right: 12px;
            line-height: normal;
            //margin-bottom: 5px;
            font-weight: 500;

            //&:nth-last-of-type(1) {
            //    display: none;
            //    position: absolute;
            //    top: 0;
            //    bottom: 0;
            //    margin: auto;
            //    z-index: 2;
            //}
          }
        }
      }
    }
  }

  //open menu

  .Main-menu__open {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 99;
    inset: 0;
    //background-color: #26201E;
    overflow: hidden;
    display: none;
    transform: translateY(-100%);
    padding-top: 100px;

    &:after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      inset: 0;
      /* background-color: #191919; */
      background-color: rgba(25, 25, 25, 0.7);
      z-index: 3;
      transition: 0.4s ease;
    }

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      background-color: rgb(38, 32, 30);
    }

    .container {
      position: relative;
      z-index: 4;
      height: 100%;
    }

    &__shadow {
      position: absolute;
      top: -110px;
      right: -140px;
      z-index: 4;
    }

    &__bg {
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      z-index: 2;
      .open-img {
      }

      ul {
        li {
          position: absolute;
          height: 100%;
          width: 100%;
          inset: 0;

          opacity: 0;
          transform: translateX(30px);
        }
      }
    }

    &__top {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__close {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        letter-spacing: 1px;
        color: ${White};
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin-right: 10px;
        }
      }
    }

    &__items {
      display: flex;
      justify-content: space-between;
      padding-top: 150px;
      position: relative;
      z-index: 2;

      &__single {
        width: 25%;
        //transition: .3s ease;
        //visibility: hidden;
        transform: translateY(40px);

        h4 {
          color: ${White};
          font-size: 24px;
          line-height: 32px;
          font-weight: bold;
          margin-bottom: 40px;
          opacity: 0;
        }

        li {
          opacity: 0;

          a {
            color: ${White} !important;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 20px;
            display: flex;
            transition: 0.4s ease;

            &:hover {
              padding-left: 10px;
            }
          }

          &:nth-last-of-type(1) a {
            margin-bottom: 0;
          }
        }

        &:hover {
          opacity: 1 !important;
        }
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 60px;
      width: 100%;
      align-items: end;
      left: 15px;
      right: 15px;
      margin: auto;

      &__single {
        width: 100%;
        opacity: 0;
        transform: translateY(40px);

        p,
        .address {
          width: 70%;
          display: block;
        }

        p,
        a {
          color: ${White};
        }

        ul {
          display: flex;

          li {
            a {
              height: 25px;
              width: 25px;
              background-color: ${hover};
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.4s ease;
              position: relative;
              overflow: hidden;

              &:after {
                content: "";
                position: absolute;
                height: 0%;
                width: 0%;
                inset: 0;
                background-color: ${White};
                transition: 0.5s ease;
                margin: auto;
              }

              img {
                transition: 0.3s ease;
                z-index: 2;
              }

              &:hover {
                &:after {
                  height: 100%;
                  width: 100%;
                }

                img {
                  filter: invert(100%) sepia(66%) saturate(2%) hue-rotate(31deg)
                    brightness(108%) contrast(101%);
                }
              }
            }

            &:not(:nth-last-of-type(1) a) {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }

  &.menu-hover {
    .Main-menu__open__items__single {
      opacity: 0.3;
    }

    .Main-menu__open {
      &:after {
        background-color: rgba(25, 25, 25, 0.7);
        //background-color: red;
      }
    }
  }

  @media (max-width: 768px) {
    .Main-menu__wrap__menu > ul {
      display: none;
    }

    .Main-menu__wrap__menu__hamburger__text {
      display: none;
    }

    .Main-menu__wrap__menu__hamburger__line {
      margin-right: 0;

      span {
        width: 30px;
      }
    }
  }

  &.menu-opened {
    .Main-menu__wrap__menu__hamburger__line {
      span {
        display: none;
      }

      img {
        display: block;
        height: 25px;
      }
    }

    .Main-menu__wrap__menu__hamburger__text p {
      //opacity: 0;

      //&.close-menu {
      //    display: block;
      //    opacity: 1;
      //}
    }

    .click {
      display: none;
    }

    .click-close {
      display: flex;
    }
  }
`;
export default Component;
