import React, { useEffect, useState } from "react";
import Link from "next/link";
import styled from "styled-components";
import { hover, text, title, whites } from "../styles/globalStyleVars";
import { TimelineLite } from "gsap";
import { usePathname, useRouter } from "next/navigation";
import Cookies from "js-cookie";

const MyComponent = () => {
  // const store = useSelector(store => store?.home)
  const pathName = usePathname();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // menu fixed on scroll
  useEffect(() => {
    if (document.body.classList.contains("scroll-down")) {
      document.body.classList.remove("scroll-down");
    }
  });

  useEffect(() => {
    const body = document.body;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;
    let howMuchScroll;

    if (window.screen.width < 991) {
      howMuchScroll = 150;
    } else {
      howMuchScroll = 150;
    }

    window.addEventListener("scroll", () => {
      let currentScroll = window.pageYOffset;

      if (currentScroll <= howMuchScroll) {
        body.classList.remove(scrollUp);
        body.classList.remove(scrollDown);
        return;
      }

      if (currentScroll > lastScroll && currentScroll > howMuchScroll) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (currentScroll < lastScroll && currentScroll > howMuchScroll) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }

      lastScroll = currentScroll;
    });
  }, []);

  //----- mobile menu action
  useEffect(() => {
    let getMobileMenuBar = document.querySelector(".main-menu-mobile");
    let getItemsParent = document.querySelector(".main-menu-mobile__items");

    let getItems = document.querySelectorAll(".main-item");

    let getBacks = document.querySelectorAll(".sub-menu-back");
    let getHamburgerClick = document.querySelector("#open-click");
    let getHamburgerCloseClick = document.querySelector("#close-click");
    let tl = new TimelineLite();
    let tl2 = new TimelineLite();

    // menu open toggle
    if (getHamburgerClick) {
      getHamburgerClick.addEventListener("click", () => {
        getMobileMenuBar.classList.add("menu-open");
        document.body.classList.add("stop-scroll");
        tl2
          .to(getItemsParent, {
            duration: 0.2,
            display: "block",
          })
          .to(
            getItemsParent,
            {
              duration: 0.2,
              x: 0,
            },
            "-=.2"
          );
      });
    }

    if (getHamburgerCloseClick) {
      getHamburgerCloseClick.addEventListener("click", () => {
        getMobileMenuBar.classList.remove("menu-open");
        document.body.classList.remove("stop-scroll");
        if (document.querySelector(".main-item.active")) {
          getItemsParent.classList.remove("active");
          document
            .querySelector(".main-item.active")
            .classList.remove("active");
        }

        tl2
          .to(getItemsParent, {
            duration: 0.2,
            x: "100%",
          })
          .to(getItemsParent, {
            duration: 0.2,
            display: "none",
          });
      });
    }

    // sub menu toggle
    getItems.forEach((i) => {
      i.addEventListener("click", () => {
        getItemsParent.classList.add("active");
        i.classList.add("active");
      });
    });

    getBacks.forEach((i) => {
      i.addEventListener("click", (e) => {
        getItemsParent.classList.remove("active");
        i.parentNode.parentElement.classList.remove("active");
        e.stopPropagation();
      });
    });

    // on click a tag menu hide
    let getAlla = document.querySelectorAll(".main-menu-mobile a");
    getAlla.forEach((i) => {
      i.addEventListener("click", (e) => {
        // e.stopPropagation();
        getMobileMenuBar.classList.remove("menu-open");
        document.body.classList.remove("stop-scroll");
        setTimeout(() => {
          if (document.querySelector(".main-item.active")) {
            getItemsParent.classList.remove("active");
            document
              .querySelector(".main-item.active")
              .classList.remove("active");
          }
        }, 300);

        tl2
          .to(getItemsParent, {
            duration: 0.3,
            x: "100%",
          })
          .to(getItemsParent, {
            duration: 0.3,
            display: "none",
          });
      });
    });
  }, []);

  // on click hide menu
  useEffect(() => {
    const getAList = document.querySelectorAll(".main-menu__left li");
    getAList.forEach((e) => {
      e.addEventListener("click", function (i) {
        if (e.querySelector("ul")) {
          e.querySelector("ul").style.display = "none";
        }
        setTimeout(() => {
          const getUl = document.querySelectorAll(".main-menu__left li ul");
          getUl.forEach((e) => {
            e.removeAttribute("style");
          });
        }, 300);
      });
    });

    // const getA = document.querySelectorAll('.main-menu__left li ul li a');
    // getA.forEach(e => {
    //     e.addEventListener('click', function (i) {
    //         document.querySelector('.main-menu').style.display = 'none'
    //         setTimeout(() => {
    //             document.querySelector('.main-menu').removeAttribute('style')
    //         }, 500)
    //     });
    // })
  }, []);
  const token = Cookies.get("user-token");
  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (token) {
      setLogin(true);
    }
  }, [token]);

  const handleLogout = (selected) => {
    Cookies.remove("user-token", { path: "/" }); // removed!
    Cookies.remove("user-token-auth", { path: "/" }); // removed!
    window.location.href = "/";
    // window.location.href = 'https://ucb-dcastaliawebs-projects.vercel.app';
  };
  return (
    <>
      {/*mobile menu*/}
      <StyledMobileMenu className="main-menu-mobile">
        <div className="main-menu-mobile__bar">
          <div className="main-menu-mobile__bar__logo">
            <Link prefetch={true} href={"/"}>
              <img
                alt=""
                width={168}
                height={40}
                src={"/images/static/logo.svg"}
              />
            </Link>
          </div>

          <div className="main-menu-mobile__bar__hamburger">
            <ul>
              <li className="hover-here">
                <svg
                  id="open-click"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="18"
                  viewBox="0 0 32 18"
                >
                  <g
                    id="Group_19820"
                    data-name="Group 19820"
                    transform="translate(-329 -31)"
                  >
                    <line
                      id="Line_3"
                      data-name="Line 3"
                      x2="30"
                      transform="translate(330 32)"
                      fill="none"
                      stroke="#f2f2f2"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_4"
                      data-name="Line 4"
                      x2="30"
                      transform="translate(330 40)"
                      fill="none"
                      stroke="#f2f2f2"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_5"
                      data-name="Line 5"
                      x2="20"
                      transform="translate(330 48)"
                      fill="none"
                      stroke="#f2f2f2"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>

                <svg
                  id="close-click"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.042"
                  height="24.042"
                  viewBox="0 0 24.042 24.042"
                >
                  <g
                    id="Group_24004"
                    data-name="Group 24004"
                    transform="translate(-337.372 -27.979)"
                  >
                    <line
                      id="Line_4"
                      data-name="Line 4"
                      x2="30"
                      transform="translate(338.787 29.393) rotate(45)"
                      fill="none"
                      stroke="#f2f2f2"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_12435"
                      data-name="Line 12435"
                      x2="30"
                      transform="translate(360 29.393) rotate(135)"
                      fill="none"
                      stroke="#f2f2f2"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </li>
            </ul>
          </div>
        </div>

        <div className="main-menu-mobile__items">
          <ul>
            <li className={"menu-btn"}>
              <Link  prefetch={true}
                target={"_blank"}
                style={{ width: "100%", display: "block" }}
                href={"tel:+8801938886333"}
              >
                <img src={"/images/static/m-menu2.svg"} alt="Telephone" />
                UInvest
              </Link>
            </li>

            {login ? (
              <li onClick={handleLogout} className={"menu-btn logout"}>
                Log Out
              </li>
            ) : (
              ""
            )}

            <li className="main-item">
              <Link  prefetch={true} className={pathName === "/" ? "active" : ""} href={"/"}>
                Home
              </Link>
              <span></span>
            </li>

            <li
              className={
                pathName.startsWith("/about-us")
                  ? "active main-item"
                  : "main-item"
              }
            >
              About Us
              <span className={"parent-arrow"}>
                <img src="/images/static/mb-arrow.svg" alt="Arrow" />
              </span>
              <ul>
                <p className="sub-menu-back">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g
                      id="Group_25543"
                      data-name="Group 25543"
                      transform="translate(0 30) rotate(-90)"
                    >
                      <rect
                        id="Hover"
                        width="30"
                        height="30"
                        transform="translate(0 30) rotate(-90)"
                        fill="#fff"
                      />
                      <g
                        id="Group_25541"
                        data-name="Group 25541"
                        transform="translate(8 18.5) rotate(-90)"
                      >
                        <line
                          id="Line_12499"
                          data-name="Line 12499"
                          x2="7"
                          y2="7"
                          fill="none"
                          stroke="#191919"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                        <line
                          id="Line_12500"
                          data-name="Line 12500"
                          y1="7"
                          x2="7"
                          transform="translate(0 7)"
                          fill="none"
                          stroke="#191919"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                      </g>
                    </g>
                  </svg>

                  <strong>About Us</strong>
                </p>
                {/*<h3>About Us</h3>*/}

                <li>
                  <Link prefetch={true} href={"/about-us#our_story"}>Our Story</Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/about-us#mission_vision"}>Mission, Vision</Link>
                </li>

                <li>
                  <Link prefetch={true} href={"/about-us#corporate_values"}>
                    Corporate Values
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/about-us#management_team"}>
                    Management Team
                  </Link>
                </li>
                <li>
                  <Link  prefetch={true} href={"/digital-booth"}>Digital Booth</Link>
                </li>
              </ul>
            </li>

            <li
              className={
                pathName.startsWith("/services")
                  ? "active main-item"
                  : "main-item"
              }
            >
              Services
              <span className={"parent-arrow"}>
                <img src="/images/static/mb-arrow.svg" alt="Arrow" />
              </span>
              <ul>
                <p className="sub-menu-back">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g
                      id="Group_25543"
                      data-name="Group 25543"
                      transform="translate(0 30) rotate(-90)"
                    >
                      <rect
                        id="Hover"
                        width="30"
                        height="30"
                        transform="translate(0 30) rotate(-90)"
                        fill="#fff"
                      />
                      <g
                        id="Group_25541"
                        data-name="Group 25541"
                        transform="translate(8 18.5) rotate(-90)"
                      >
                        <line
                          id="Line_12499"
                          data-name="Line 12499"
                          x2="7"
                          y2="7"
                          fill="none"
                          stroke="#191919"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                        <line
                          id="Line_12500"
                          data-name="Line 12500"
                          y1="7"
                          x2="7"
                          transform="translate(0 7)"
                          fill="none"
                          stroke="#191919"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                      </g>
                    </g>
                  </svg>
                  <strong>Services</strong>
                </p>

                <li>
                  <Link prefetch={true} href={"/services/individual-account"}>
                    Individual Account
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/services/corporate-account"}>
                    Corporate Account
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/services/institutional-account"}>
                    Institutional Account
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/services/foreign-account"}>
                    Foreign Account
                  </Link>
                </li>
              </ul>
            </li>

            <li className="main-item">
              UCB Hub
              <span className={"parent-arrow"}>
                <img src="/images/static/mb-arrow.svg" alt="Arrow" />
              </span>
              <ul>
                <p className="sub-menu-back">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g
                      id="Group_25543"
                      data-name="Group 25543"
                      transform="translate(0 30) rotate(-90)"
                    >
                      <rect
                        id="Hover"
                        width="30"
                        height="30"
                        transform="translate(0 30) rotate(-90)"
                        fill="#fff"
                      />
                      <g
                        id="Group_25541"
                        data-name="Group 25541"
                        transform="translate(8 18.5) rotate(-90)"
                      >
                        <line
                          id="Line_12499"
                          data-name="Line 12499"
                          x2="7"
                          y2="7"
                          fill="none"
                          stroke="#191919"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                        <line
                          id="Line_12500"
                          data-name="Line 12500"
                          y1="7"
                          x2="7"
                          transform="translate(0 7)"
                          fill="none"
                          stroke="#191919"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                      </g>
                    </g>
                  </svg>
                  <strong>UCB Hub</strong>
                </p>
                <li>
                  <Link prefetch={true} href={"/research"}>Research Portal</Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/ucb-hub#expert"}>Our Experts</Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/ucb-hub#download"}>Download Hub</Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/investment-calculator"}>
                    Investment Calculator
                  </Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/tax-calculator"}>Tax Calculator</Link>
                </li>
              </ul>
            </li>

            <li className="main-item">
              Misc
              <span className={"parent-arrow"}>
                <img src="/images/static/mb-arrow.svg" alt="Arrow" />
              </span>
              <ul>
                <p className="sub-menu-back">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g
                      id="Group_25543"
                      data-name="Group 25543"
                      transform="translate(0 30) rotate(-90)"
                    >
                      <rect
                        id="Hover"
                        width="30"
                        height="30"
                        transform="translate(0 30) rotate(-90)"
                        fill="#fff"
                      />
                      <g
                        id="Group_25541"
                        data-name="Group 25541"
                        transform="translate(8 18.5) rotate(-90)"
                      >
                        <line
                          id="Line_12499"
                          data-name="Line 12499"
                          x2="7"
                          y2="7"
                          fill="none"
                          stroke="#191919"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                        <line
                          id="Line_12500"
                          data-name="Line 12500"
                          y1="7"
                          x2="7"
                          transform="translate(0 7)"
                          fill="none"
                          stroke="#191919"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                      </g>
                    </g>
                  </svg>
                  <strong>Research Portal</strong>
                </p>

                <li>
                  <Link  prefetch={true} href={"/career"}>Career</Link>
                </li>
                <li>
                  <Link prefetch={true} href={"/contact-us"}>Contact</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </StyledMobileMenu>
    </>
  );
};

// mobile menu

const StyledMobileMenu = styled.section`
  position: fixed;
  width: 100%;
  z-index: 2000000000999;
  top: 0;
  left: 0;

  .menu-btn {
    background: #ffffff;
    height: 50px;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    display: flex;
    align-items: center;
    margin-bottom: 60px !important;
    max-width: calc(100vw - 30px);

    &.logout {
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      text-align: center;
      vertical-align: middle;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      color: #191919 !important;
    }

    a {
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      color: #191919 !important;

      img {
        margin-right: 10px;
      }
    }
  }

  .parent-arrow {
  }

  @media (min-width: 991px) {
    display: none !important;
  }

  .main-menu-mobile__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(242, 242, 242, 0.5);
    padding: 0;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    background-color: rgba(34, 34, 34, 0.4);
    z-index: 999;
    transition: all 0.4s ease;
    height: 80px;

    &__hamburger {
      ul {
        height: 80px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e62e43;

        li {
          //&:nth-of-type(1) {
          //  margin-right: 15px;
          //}

          svg {
            &:nth-of-type(2) {
              display: none;
            }
          }
        }
      }
    }
  }

  .main-menu-mobile__items {
    padding: 0 15px;
    position: relative;
    transform-origin: top left;
    transition: all 0.3s ease-out;
    //display: none;
    height: calc(${`100svh`});
    //margin-top: 170px;
    //overflow-x: hidden;
    //overflow-y: auto;
    overflow-y: auto;
    padding-bottom: 10px;
    padding-top: 150px;
    overflow-x: hidden;
    background-color: #191919;
    width: 200vw;
    transform: translateX(100%);
    display: none;

    ul {
      width: 100%;
      overflow-y: auto;
      height: calc(100vh - 40px);

      li {
        text-transform: capitalize;
        display: block;
        width: 100%;
        color: #f2f2f2;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        //position: relative;

        &.main-item {
          //border-bottom: 1px solid rgba(198, 198, 198, 0.5);
          //padding-bottom: 15px;

          span {
            content: "";
            width: calc(100vw - 30px);
            background-color: rgba(198, 198, 198, 0.5);
            //position: absolute;
            //bottom: 0;
            //left: 0;
            height: 1px;
            display: block;
            margin-top: 30px;
            position: relative;

            img {
              position: absolute;
              right: 0;
              bottom: 30px;
              transform: rotate(-90deg);
            }
          }

          a {
            color: #ffffff;
            font-size: 20px;
            line-height: 28px;
            font-weight: bold;
          }

          &:nth-last-child(1) span {
            //background-color: transparent;
          }
        }

        &:hover {
          color: ${hover};
        }

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 30px;
        }

        a {
          color: #ffffff;
          font-size: 20px;
          line-height: 28px;
          font-weight: bold;
        }

        //sub menus

        ul {
          left: 0;
          list-style: none;
          margin: 0;
          position: absolute;
          top: 130px;
          padding: 0 15px;
          //height: 100vh;
          opacity: 0;
          transform: translate3d(100vw, 0, 0);
          transition: all 0.3s ease;
          //padding-bottom: 40px;
          width: 100vw;
          padding-bottom: 25px;

          p {
            margin-bottom: 35px;

            svg {
              margin-top: -5px;
            }

            strong {
              border-color: transparent !important;
              font-size: 20px;
              font-weight: 500;
              color: white;
              padding-left: 20px;
              line-height: 28px;
            }
          }

          h3 {
            font-size: 30px;
            line-height: 40px;
            font-weight: 400;
            font-family: ${title};
            margin: 0;
            text-transform: capitalize;
            padding-bottom: 20px;
            border-bottom: 1px solid ${hover};
            margin-bottom: 40px;
          }

          li {
            &:not(:nth-last-of-type(1)) {
              //margin-bottom: 30px;
              margin-bottom: 20px;

              a {
                border-bottom: 1px solid rgba(198, 198, 198, 0.5);
              }
            }

            a {
              font-size: 20px;
              line-height: 28px;
              text-transform: capitalize;
              display: block;
              width: 100%;

              padding-bottom: 15px;
              color: #f2f2f2;
            }
          }
        }

        &.active {
          ul {
            opacity: 1;
            z-index: 2;
          }
        }
      }
    }

    &.active {
      transform: translate3d(-100vw, 0, 0) !important;
    }
  }

  //toggle action

  &.menu-open {
    .main-menu-mobile__bar {
      background-color: #000000;

      &__logo {
        img {
          // content: url(${"LogoBlack"});
        }
      }

      &__hamburger {
        li:nth-of-type(1) {
          svg {
            g {
              stroke: ${text};
            }

            path {
              fill: ${text};
            }
          }
        }

        #open-click {
          display: none;
        }

        #close-click {
          display: block;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .menu-btn {
      width: 100%;
    }
  }

  .active {
    color: ${hover} !important;
  }
`;

export default React.memo(MyComponent);
