"use client";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Title from "./Title";
import {
  Black,
  hover,
  text,
  title,
  Transition,
} from "@/styles/globalStyleVars";
import axios from "axios";
import Link from "next/link";
import { CloseIcon } from "next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon";
import SimpleBar from "simplebar-react";
import Form from "@/components/common/Form";
import HtmlParser from "react-html-parser";

const MyComponent = () => {
  // offset
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const offsetFunk = () => {
      setOffset(document.querySelector(".footer .container").offsetLeft + 15);
    };
    window.addEventListener("resize", () => {
      offsetFunk();
    });
    offsetFunk();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    setShow(true);
  };

  const [data, setData] = useState(null);
  // api call
  useEffect(() => {
    fetch("https://cms.ucbstock.com.bd//wp-json/get-settings/all")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);


  return (
    <StyledComponent offset={offset} className="footer">
      <div className="footer-contact">
        <Link href={"javascript:void(0)"} onClick={handleShow}>
          <span>Contact Us</span>{" "}
          <img src="/images/static/arrow-big.svg" alt="Contact Us Arrow" />
        </Link>
      </div>
      <Container>
        <Row className={"footer__top"}>
          <Col sm={9} className={"footer__logo"}>
            <img src="/images/static/logo-black.png" alt="UCB Stock Brokerage LTD. Logo" />
          </Col>
          <Col sm={3}>
            <ul className={"footer__social"}>
              {data?.social?.[0]?.["opt-link"]?.url && (
                <li>
                  <a
                    target={"_blank"}
                    href={data?.social?.[0]?.["opt-link"]?.url}
                  >
                    <img src="/images/static/fb.svg" alt="UCB Stock Brokerage LTD Facebook" />
                  </a>
                </li>
              )}
              {data?.social?.[1]?.["opt-link"]?.url && (
                <li>
                  <a
                    target={"_blank"}
                    href={data?.social?.[1]?.["opt-link"]?.url}
                  >
                    <img src="/images/static/insta.svg" alt="UCB Stock Brokerage LTD Instragram" />
                  </a>
                </li>
              )}
              {data?.social?.[2]?.["opt-link"]?.url && (
                <li>
                  <a
                    target={"_blank"}
                    href={data?.social?.[2]?.["opt-link"]?.url}
                  >
                    <img src="/images/static/youtube.svg" alt="UCB Stock Brokerage LTD Youtube" />
                  </a>
                </li>
              )}
              {data?.social?.[3]?.["opt-link"]?.url && (
                <li>
                  <a
                    target={"_blank"}
                    href={data?.social?.[3]?.["opt-link"]?.url}
                  >
                    <img src="/images/static/in.svg" alt="UCB Stock Brokerage LTD Linkedin" />
                  </a>
                </li>
              )}
            </ul>
          </Col>
        </Row>

        <Row className={"footer__bottom"}>
          <Col sm={3} className={"footer__address"}>
            <h4>Find Us</h4>
            <p>
              <a
                target={"_blank"}
                href="https://www.google.com/maps/place/United+Commercial+Bank+%7C+Head+Office/@23.7874374,90.4139519,17z/data=!3m1!4b1!4m6!3m5!1s0x3755c7a1ee5193d9:0xc4a50604869e47f5!8m2!3d23.7874374!4d90.4165268!16s%2Fg%2F1thq86np?sca_esv=e4b6c45cd17b4d13&sca_upv=1&rlz=1C1ONGR_enBD1089BD1090&output=search&q=UCBL,+Bulus+Centre,+Gulshan+Avenue,+Dhaka&source=lnms&fbs=AEQNm0Aa4sjWe7Rqy32pFwRj0UkW1DRbm01j6DCVS0r1sTxn7oKTGqI4IKdl-uwVoUv_nV3S07eEReNDnqGxeFIt5XEnXuVE2K_kZcfo858YoXmS0TjPJy3v6Gy88h03iallEurBDnpUfXlH9bwwGBaHbyLatFaqhYjfrLJXFgIuyuS7tniK4VM7X-nDhQ2tImKyEo8ek6Ak&ved=1t:200715&ictx=111&entry=tts&g_ep=EgoyMDI0MDYxOS4xKgBIAVAD"
              >
                {HtmlParser(data?.address)}
              </a>
            </p>
            <a href={`mailto:${data?.email}`}>{data?.email}</a>
            <a href={`tel:${data?.phone}`}>{data?.phone}</a>
          </Col>

          <Col sm={3}>
            <h4>UCB Stock</h4>
            <ul>
              <li>
                <Link href={"/"} prefetch={true}>Home</Link>
              </li>
              <li>
                <Link href={"/about-us"} prefetch={true}>About Us</Link>
              </li>
              <li>
                <Link href={"/contact-us"} prefetch={true}>Contact</Link>
              </li>
              <li>
                <Link href={"/digital-booth"} prefetch={true}>Digital Booth</Link>
              </li>
              <li>
                <Link href={"/ucb-hub"} prefetch={true}>News & Events</Link>
              </li>
              <li>
                <Link href={"/open-a-bo-account"} prefetch={true}>Open a BO Account</Link>
              </li>
              <li>
                <Link href={"/career"} prefetch={true}>Career</Link>
              </li>
            </ul>
          </Col>

          <Col sm={3}>
            <h4>Services</h4>
            <ul>
              <li>
                <Link prefetch={true} href={"/services/individual-account"}>
                  Individual Account
                </Link>
              </li>
              <li>
                <Link prefetch={true} href={"/services/corporate-account"}>
                  Corporate Account
                </Link>
              </li>
              <li>
                <Link prefetch={true} href={"/services/institutional-account"}>
                  Institutional Account
                </Link>
              </li>
              <li>
                <Link prefetch={true} href={"/services/foreign-account"}>Foreign Account</Link>
              </li>
            </ul>
          </Col>

          <Col sm={3}>
            <h4>UCB Hub</h4>
            <ul>
              <li>
                <Link  prefetch={true} href={"/research"}>Research Portal</Link>
              </li>
              <li>
                <Link prefetch={true} href={"/ucb-hub#expert"}>Our Experts</Link>
              </li>
              <li>
                <Link prefetch={true} href={"/ucb-hub#download"}>Download Hub</Link>
              </li>

              <li>
                <Link prefetch={true} href={"/investment-calculator"}>
                  Investment Calculator
                </Link>
              </li>
              <li>
                <Link prefetch={true} href={"/tax-calculator"}>Tax Calculator</Link>
              </li>
            </ul>
          </Col>
        </Row>

        <Row className={"copyright"}>
          <Col sm={3}>
            <p>© {new Date().getFullYear()} UCB Stock Brokerage.</p>
          </Col>
          <Col sm={6}>
            <a target={"_blank"} href="https://dcastalia.com/">
              Designed & Developed by Dcastalia{" "}
              <img src="/images/static/dc-logo.svg" alt="Dcastalia" />
            </a>
          </Col>
          <Col sm={3}>
            <p>All Rights Reserved</p>
          </Col>
        </Row>
      </Container>

      <Modal
        className={`team-modal team-modal-form  for-home`}
        show={show}
        onHide={handleClose}
      >
        <div className="team-modal__close" onClick={handleClose}>
          <CloseIcon />
        </div>
        <SimpleBar autoHide={true} style={{ maxHeight: "85vh" }}>
          <div className="team-modal__content">
            <h3 className="top-text">Contact</h3>
            <h3 className="bottom-text">us</h3>
            <Form />
          </div>
        </SimpleBar>
      </Modal>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  position: relative;
  background: white;

  .footer-contact {
    height: 96px;
    padding-left: 15px;
    background-color: ${hover};
    padding-right: 70px;
    width: calc(100% - ${(p) => p.offset + "px"});
    //margin-top: -48px;
    transform: translateY(-50%);
    position: relative;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      bottom: 0;
      width: 0;
      transition: 0.6s ${Transition};
      background-color: rgba(25, 25, 25, 0.25);
      opacity: 0;
    }

    &:hover {
      &:after {
        width: 100%;
        opacity: 1;
      }
    }

    a {
      font-size: 36px;
      line-height: 40px;
      font-weight: 600;
      color: #fff !important;
      text-transform: capitalize;
      font-family: ${title};
      display: flex;
      align-items: center;
      justify-content: end;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
      padding-top: 7px;
      span {
        position: relative;
      }

      img {
        margin-left: 45px;
        position: relative;
      }
    }
  }

  .footer__top {
    padding-top: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }

  .footer__logo {
    img {
      height: 70px;
    }
  }

  .footer__social {
    display: flex;

    li {
      a {
        height: 25px;
        width: 25px;
        background-color: ${hover};
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease;

        &:hover {
          background-color: #000;
        }
      }

      &:not(:nth-last-of-type(1) a) {
        margin-right: 15px;
      }
    }
  }

  h4 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: rgba(25, 25, 25, 0.5);
    margin-bottom: 15px;
  }

  p {
    font-weight: 500;
  }

  a {
    color: ${text};
    display: block;
    font-weight: 500;
  }

  .footer__address {
    p {
      margin-bottom: 30px;
      max-width: 80%;
    }

    a {
      &:not(:nth-last-of-type(1)) {
        margin-bottom: 15px;
      }
    }
  }

  .footer__bottom {
    ul {
      li {
        a {
        }

        &:not(:nth-last-of-type(1)) a {
          margin-bottom: 15px;
        }
      }
    }
  }

  .copyright {
    margin-top: 40px;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;

    &:after {
      content: "";
      position: absolute;
      height: 1px;
      left: 15px;
      right: 15px;
      top: 0;
      background-color: rgba(25, 25, 25, 0.2);
    }

    p,
    a {
      color: rgba(25, 25, 25, 0.5);
      font-weight: 400;
    }
  }

  @media (max-width: 991px) {
    .footer__top {
      .col-sm-9,
      .col-sm-3 {
        min-width: 50%;
        max-width: 50%;
      }

      .col-sm-3 {
        display: flex;
        justify-content: end;
      }
    }

    .footer__bottom {
      .col-sm-3 {
        min-width: 50%;
        margin-bottom: 45px;
      }
    }

    .copyright {
      margin-top: 0;
    }
  }

  @media (max-width: 767px) {
    .footer-contact {
      padding-right: 40px;

      a {
        justify-content: space-between;
        font-size: 32px;

        span {
          white-space: nowrap;
        }

        img {
          margin-right: 5px;
        }
      }
    }

    .footer__top {
      margin-bottom: 45px;

      .col-sm-9,
      .col-sm-3 {
        min-width: 100%;
      }

      .col-sm-3 {
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
      }
    }

    .copyright {
      .col-sm-3 {
        &:nth-last-of-type(1) {
          margin-top: 12px;
        }
      }
    }
  }
`;

export default MyComponent;
