"use client";
import StyledComponentsRegistry from "@/lib/registry";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import GlobalStyle from "@/styles/globalStyle";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import {
  useRouter,
  useParams,
  useSearchParams,
  usePathname,
} from "next/navigation";
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";
import useDeviceType from "@/components/hooks/useDeviceType";
import MobileMenu from "@/components/MobileMenu";
import "../styles/globalStyle";
import "./global.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "react-modal-video/css/modal-video.min.css";
import "react-datepicker/dist/react-datepicker.css";
import FramerMotion from "@/components/common/FramerMotion";
import { Draggable } from "gsap/Draggable";
import SmoothScroll from "@/components/SmoothScroll";
import StickyMessage from "@/components/StickyMessage";
import PageTransition from "@/components/animation/PageTransition";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {
  ParallaxX,
  ParallaxXR,
  splitLeft,
} from "@/components/animations/TextAnimations";
// import { ParallaxXR, splitLeft } from "@/components/animation/TextAnimation";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { FadeUp } from "@/components/animations/TextAnimations";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Gtag from "@/app/Gtag";

// You will need a ResizeObserver polyfill for browsers that don't support it! (iOS Safari, Edge, ...)
export default function RootLayout({ children }) {
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);
  const params = useParams();
  const router = usePathname();
  const isMobile = useDeviceType();

  const getMenuClass = () => {
    if (
      router.startsWith("/research/sign-up") ||
      router.startsWith("/research/login") ||
      router.startsWith("/research/forget-password") ||
      router.startsWith("/research/reset-password") ||
      router.startsWith("/investment-calculator") ||
      router.startsWith("/tax-calculator") ||
      router.startsWith("/open-a-bo-account")
    ) {
      return "menu-bg-v2";
    }
    return "";
  };

  const pathName = usePathname();
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);

  const el = useRef();
  let smoother;
  useGSAP(() => {
    ScrollTrigger.refresh();
    if (window.innerWidth > 768) {
      smoother = ScrollSmoother.create({
        smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true, // looks for data-speed and data-lag attributes on elements
        // speed: 2,
      });
    }
  }, [pathName]);

  React.useEffect(() => {
    if (pathName === "/") {
      document.body.classList.add("in-home");
    } else {
      document.body.classList.remove("in-home");
    }
  }, [pathName]);

  // useGSAP(() => {
  //   const getAllMenu = document.querySelectorAll(
  //     ".Main-menu__open__items__single a"
  //   );
  //   getAllMenu.forEach((e) => {
  //     e.addEventListener("click", (f) => {
  //       console.log("click", window.location.hash);
  //       setTimeout(() => {
  //         if (window.location.hash) {
  //           smoother.scrollTo(`${window.location.hash}`, true, "top -100px", {
  //             duration: 1,
  //           });
  //         }
  //       }, 3000);
  //     });
  //   });
  // }, []);

  // useGSAP(() => {
  //   const getAllA = document.querySelectorAll(".Main-menu__open a");
  //   getAllA.forEach((e) => {
  //     e.addEventListener("click", (f) => {
  //       if (window.location.hash) {
  //         setTimeout(() => {
  //           gsap.to(window, {
  //             duration: 0.8,
  //             scrollTo: { y: window.location.hash },
  //           });
  //         }, 1300);
  //       }
  //     });
  //   });
  // }, []);

  // animations
  // ParallaxX();
  // ParallaxXR();
  // splitLeft();
  // FadeUp();

  // for custom css on tawk.to
  useEffect(() => {
    const addCustomStyle = () => {
      const tawkIframe = document.querySelector('iframe[title="chat widget"]');

      if (tawkIframe) {
        const isMobile = window.innerWidth <= 768; // Example mobile breakpoint
        if (isMobile) {
          // Apply style directly to the iframe for mobile viewports
          tawkIframe.style.right = "15px";
        } else {
          tawkIframe.style.right = "25px";
        }

        const iframeDocument =
          tawkIframe.contentDocument || tawkIframe.contentWindow.document;

        if (iframeDocument) {
          const style = iframeDocument.createElement("style");
          style.innerHTML = `
            /* Custom CSS for Tawk.to widget */
            .tawk-button{
              width: 60px !important;
              height: 60px !important;
              left: unset !important;
              right: 0 !important;
            }
          `;
          iframeDocument.head.appendChild(style);
        }
      }
    };

    // Set a timeout to ensure the widget is fully loaded
    const observer = new MutationObserver(() => {
      addCustomStyle();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  const jsonLdData = {
    "@context": "https://schema.org/",
    "@type": "BankOrCreditUnion",
    "name": "UCB Stock Brokerage",
    "image": "",
    "@id": "",
    "url": "https://ucbstock.com.bd/",
    "telephone": "+880132 424 3200",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Bulus Center Plot-CWS-(A)-1, Road No 34, Gulshan Avenue 1212",
      "addressLocality": "Dhaka",
      "postalCode": "1212",
      "addressCountry": "BD"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 23.7937673,
      "longitude": 90.4147309
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Sunday"
      ],
      "opens": "09:00",
      "closes": "17:00"
    }
  };

  const pathname = usePathname();
  const canonicalUrl = `https://ucbstock.com.bd${pathname}`;
  return (
    <html lang="en">
    <head>
      <link rel="icon" type="image/png" href="/images/static/fav.png"/>
      <meta content="#8C263D" name="theme-color"/>
      <meta property="og:image" content="/images/static/logo.png"/>
      <link rel="preload" href="/images/static/logo.svg" as="image"/>
      <meta
          name="google-site-verification"
          content="kCYeBlL8-dFAzs31RwS6urDill3s3A8W714Y_twCeLM"
      />
      <link rel="canonical" href={canonicalUrl}/>
      {/* Canonical URL */}
      {[
        "Aeonik-Medium",
        "Aeonik-Regular",
        "Aeonik-Bold",
        "Aeonik-Light",
        "Butler",
        "Butler-Medium",
        "Butler-UltraLight",
        "Butler-ExtraBold",
        "Butler-Light",
        "Butler-Black",
        "Butler-Bold",
      ].map((font) => (
          <React.Fragment key={font}>
            <link
                rel="preload"
                href={`/fonts/${font}.woff2`}
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                href={`/fonts/${font}.woff`}
                as="font"
                type="font/woff"
                crossOrigin="anonymous"
            />
          </React.Fragment>
      ))}
      <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{__html: JSON.stringify(jsonLdData)}}
      />
      <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-3SN9DWPFPP"
      ></script>
      <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-3SN9DWPFPP');
            `,
          }}
      />
    </head>
    <body contentEditable={false}>
    <StyledComponentsRegistry>
      <TawkMessengerReact
          propertyId="66efaa9f4cbc4814f7dd16ba"
          widgetId="1i8c350dm"
      />
      <div id="root" data-simplebar data-simplebar-auto-hide="false">
        <GlobalStyle/>
        <ToastContainer/>
        {isMobile ? <MobileMenu/> : <Menu getMenuClass={getMenuClass}/>}
        <PageTransition/>
        <StickyMessage/>
        <div ref={el} id="smooth-wrapper">
          <div id="smooth-content">
            {children}
            <Footer/>
          </div>
        </div>
      </div>
    </StyledComponentsRegistry>
    </body>
    </html>
  );
}
